import { SlugEntity } from './slugEntity';
import { TagPreview } from './tag';

export interface Video extends SlugEntity {
    canonical: string | null;
    description: string;
    entityType: number;
    imagePath: string;
    metaDescription: string;
    sectionVideos: null | SectionVideo[];
    title: string;
    videoKey: string;
    tags?: TagPreview[];
    videoType: VideoType;
}

export type NullableVideo = Video | null;

export interface SectionVideo {
    sectionId: number;
    videoId: number;
}

export enum VideoType {
    YouTube = 0,
    Vidyard = 1,
}

export interface VideoFormData {
    id?: number;
    title: string;
    description: string;
    metaDescription: string;
    imagePath: string;
    videoType: VideoType;
    youtubeUrl: string;
    vidyardUrl: string;
    slug: string;
    canonical: string | null;
    redirectUrl: string | null;
    language: number;
    status: number;
    publishedDateUtc: string | null;
    tags?: TagPreview[];
    breadcrumbDisplayName: string | null;
}

export interface VideoPageData {
    video: NullableVideo;
    otherVideos: Video[] | null;
    relatedVideos: Video[] | null;
}

export type NullableVideoPageData = VideoPageData | null;
