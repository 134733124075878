import React from 'react';
import { customizableImageGalleryStyle as classes } from './styleObjects/CustomizableImageGallery';
import { Grid } from '@mui/material';
import { Picture } from '../Picture';
import { getAnimationStyle } from './helpers/AnimationStyleHelper';
import { GridContainer, ChildContainer } from '../GeotabGridComponents';
import { getLinkTargetValue } from './helpers/SectionComponentHelper';
import { CMSLink } from '@components/CMSLink';
import { v2Colors } from '@web-for-marketing/react-ui';
import { isPreviewingAnimationAtom } from '@atoms/animation';
import { useAtom } from 'jotai';
import { Heading } from '@web-for-marketing/react-ui';
import { LazyLoadContainer } from '@web-for-marketing/react-ui';
import { CustomizableImageGallery } from '@models/section/customizableImageGallerySection';
import { SectionAnimation } from '@models/section';
import { XAlignValues } from '@models/editorForm';

interface CustomizableGalleryCardsProps {
    cardProps: {
        images: CustomizableImageGallery['images'];
        title: string;
        animations: SectionAnimation;
        zIndex: number;
        size: number;
        align: XAlignValues;
        lazyLoad: boolean;
        background: boolean;
        headerSection: boolean;
    };
}

export function CustomizableGalleryCards({ cardProps }: CustomizableGalleryCardsProps): JSX.Element {
    const [isPreviewingAnimation] = useAtom(isPreviewingAnimationAtom);
    const { images, title, animations, zIndex, size, align, lazyLoad, background, headerSection } = cardProps;

    return (
        <Grid
            container
            className={animations ? 'animateContainers' : ''}
            css={{ backgroundColor: background ? v2Colors.core.cloud : '#FFF' }}
        >
            <GridContainer
                className={
                    animations.sectionAnimation
                        ? `${getAnimationStyle('sectionAnimation', true, animations, isPreviewingAnimation)}`
                        : ''
                }
                css={{ zIndex }}
            >
                {title ? (
                    <Heading
                        variant='h2'
                        component={headerSection ? 'h1' : 'h2'}
                        css={[{ maxWidth: '72rem' }, classes.titles]}
                    >
                        {title}
                    </Heading>
                ) : null}
                <ChildContainer
                    direction='row'
                    justifyContent={align}
                    alignItems='center'
                    css={classes.galleryContainer}
                >
                    {images.map((imageCard, index) => {
                        const { image, linkPath, linkEnabled, linkTarget, ariaLabel } = imageCard;
                        return (
                            <LazyLoadContainer
                                key={`${index}-${JSON.stringify(imageCard)}`}
                                lazyload={lazyLoad}
                                className={`${getAnimationStyle(
                                    `imagesAnimation${index}`,
                                    index === 0,
                                    animations,
                                    isPreviewingAnimation
                                )}`}
                                css={classes.cardContainer}
                                item
                                xs={6}
                                md={size}
                            >
                                {linkEnabled ? (
                                    <CMSLink
                                        title={linkPath}
                                        href={linkPath}
                                        aria-label={ariaLabel}
                                        target={getLinkTargetValue(linkTarget)}
                                        css={classes.linkContainer}
                                    >
                                        <Picture
                                            imagePath={image.imagePath}
                                            mobilePath={image.mobilePath}
                                            alt={image.imageAlt}
                                            mobileAlt={image.mobileAlt}
                                            css={classes.media}
                                            lazyLoad={lazyLoad}
                                            aria-hidden='true'
                                        />
                                    </CMSLink>
                                ) : (
                                    <Picture
                                        imagePath={image.imagePath}
                                        mobilePath={image.mobilePath}
                                        alt={image.imageAlt}
                                        mobileAlt={image.mobileAlt}
                                        css={classes.media}
                                        lazyLoad={lazyLoad}
                                        aria-hidden='true'
                                    />
                                )}
                            </LazyLoadContainer>
                        );
                    })}
                </ChildContainer>
            </GridContainer>
        </Grid>
    );
}
