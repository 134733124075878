import React from 'react';
import { Spec } from '@models/catalogItem';
import { IntegrationIcon } from '@components/IconComponents/DeviceSpecIcons/IntegrationIcon';
import { ConfigurationIcon } from '@components/IconComponents/DeviceSpecIcons/ConfigurationIcon';
import { ArtificialIntelIcon } from '@components/IconComponents/DeviceSpecIcons/ArtificialIntelIcon';
import { CoachingIcon } from '@components/IconComponents/DeviceSpecIcons/CoachingIcon';
import { StorageIcon } from '@components/IconComponents/DeviceSpecIcons/StorageIcon';
import { OtherIcon } from '@components/IconComponents/DeviceSpecIcons/OtherIcon';
import { Grid } from '@mui/material';
import { Heading, Text } from '@web-for-marketing/react-ui';
import { breakpoints } from '@web-for-marketing/react-ui';

interface DeviceSpecProps {
    groupedSpecs: Map<string, Spec[]>;
    className?: string;
}

const classes = {
    header: {
        '&:not(:first-child)': {
            marginTop: '6rem',
        },
        marginBottom: '6rem',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            '&:not(:first-child)': {
                marginTop: '4rem',
            },
            marginTop: '2rem',
            marginBottom: '4rem',
        },
    },
    divider: {
        flexGrow: 1,
        height: '1px',
        backgroundColor: '#D1D6DD',
        borderRadius: '1px',
    },
    icons: {
        [`@media (max-width: ${breakpoints.md}px)`]: {
            width: '28px',
            height: '28px',
        },
    },
    specText: (text: string) => {
        return text === '----' ? { margin: 'auto 0' } : {};
    },
};

const GroupedSpecIcons: Record<string, JSX.Element> = {
    Integration: <IntegrationIcon css={classes.icons} />,
    Configuration: <ConfigurationIcon css={classes.icons} />,
    'Artificial Intelligence': <ArtificialIntelIcon css={classes.icons} />,
    Coaching: <CoachingIcon css={classes.icons} />,
    Storage: <StorageIcon css={classes.icons} />,
    Other: <OtherIcon css={classes.icons} />,
};

export function DeviceSpecs({ groupedSpecs, className }: DeviceSpecProps): JSX.Element {
    return (
        <Grid container direction='column' className={className}>
            {Array.from(groupedSpecs.entries()).map(([header, specs], index) => (
                <React.Fragment key={index}>
                    <Grid container alignItems='center' columnGap={{ xs: 1, md: 2 }} css={classes.header}>
                        {GroupedSpecIcons[header]}
                        <Heading variant='h4'>{header}</Heading>
                        <div css={classes.divider}></div>
                    </Grid>
                    <Grid container textAlign='center' columnSpacing={2} rowGap={4}>
                        {specs.map((spec, index) => (
                            <Grid key={index} container item direction='column' xs={6} md={4}>
                                {spec.subHeader ? (
                                    <Text color='primary' weight='bold'>
                                        {spec.subHeader}
                                    </Text>
                                ) : null}
                                <Text key={index} color='primary' css={classes.specText(spec.detail)}>
                                    {spec.detail.split('\n').map((line, lineIndex) => (
                                        <React.Fragment key={lineIndex}>
                                            {line}
                                            <br />
                                        </React.Fragment>
                                    ))}
                                </Text>
                            </Grid>
                        ))}
                    </Grid>
                </React.Fragment>
            ))}
        </Grid>
    );
}
